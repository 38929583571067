import $ from 'jquery';

export default class HeaderAnimation {

    constructor() {
        var self = this;
        this.titleFontSize = this.retrieveElFontSizeRem('h1 .fat-title');
    }

    init() {
        var self = this;
        var spanEls = $("h1 span.fat-title, h1 span.coders-title")

        //__ reset font default size
        var sectionTitleHeight = $(window).height();
        $(window).on('resize', function () {
            spanElscss('font-size','revert-layer');
            self.titleFontSize = self.retrieveElFontSizeRem('h1 .fat-title');
        });

        $(window).on('scroll', function () {

            //__ font size
            var scrollTop = $(window).scrollTop();
            var maxScroll = sectionTitleHeight;
            if (scrollTop > maxScroll) scrollTop = maxScroll;
            var fontSize = self.titleFontSize + (scrollTop / maxScroll) * (160 - self.titleFontSize);

            //__ opacity
            var opacity = 1;
            var halfSectionHeight = sectionTitleHeight / 2;
            if (scrollTop > halfSectionHeight) {
                opacity = 1 - ((scrollTop - halfSectionHeight) / halfSectionHeight);
            }

            //__ apply
            spanEls.css({
                "font-size": fontSize + "rem",
                "opacity": opacity
            });

            //__ video opacity
            var videoOpacity = 0;
            var thirdSectionHeight = $(window).height() / 3 * 2;
            if (scrollTop >= halfSectionHeight && scrollTop <= 1000) {
                videoOpacity = (scrollTop - halfSectionHeight) / halfSectionHeight; // Interpolation de 0 à 1 entre 500 et 1000 pixels
            } else if (scrollTop > 1000) {
                videoOpacity = 1;
            }

            //__ apply
            $(".container-video").css("opacity", videoOpacity);

        });
    }

    //__ return element font-size in rem
    retrieveElFontSizeRem(el){
        var fontSizePx = parseFloat($(el).css('font-size'));
        var baseFontSizePx = parseFloat($('html').css('font-size'));
        return fontSizePx / baseFontSizePx;
    }


}