export default class HeaderSubtitleCodeTypping {
    constructor(selector) {
        this.node = document.querySelector(selector);
        if (!this.node) throw new Error(`Element not found: ${selector}`);
        this.init();
    }

    async init() {
        await this.sleep(1000);
        this.node.innerText = "";
        // await this.node.type('Hello, ');
        while (true) {
            await this.node.type('We are highly skilled developers ☝');
            await this.sleep(3000);
            await this.node.delete('are highly skilled developers ☝');
            await this.sleep(250);
            // await this.node.type('We build all the digital products you need');
            // await this.sleep(3000);
            // await this.node.delete('build all the digital products you need');
            // await this.sleep(500);
            // await this.node.type('build mobile apps');
            // await this.sleep(2500);
            // await this.node.delete('build mobile apps');
            // await this.sleep(500);
            // await this.node.type('are web sites & e-commerce builders');
            // await this.sleep(2500);
            // await this.node.delete('are web sites & e-commerce builders');
            // await this.sleep(500);
            // await this.node.type('are API, Backend, e-commerce, UI & UX design killers...');
            // await this.sleep(2500);
            // await this.node.delete('are API, Backend, e-commerce, UI & UX design killers...');
            await this.sleep(250);
            await this.node.type('are the digital veteran coders you need');
            await this.sleep(2500);
            await this.node.delete('We are the digital veteran coders you need');
            await this.sleep(250);
            await this.node.type('Your amazing digital product factory');
            await this.sleep(2500);
            return false;
            // await this.sleep(2000);
            // await this.node.delete('World!');
        }
    }

    sleep(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }
}

// Classe TypeAsync étendue
class TypeAsync extends HTMLSpanElement {
    get typeInterval() {
        const randomMs = 50 * Math.random();
        return randomMs < 50 ? 10 : randomMs;
    }

    async type(text) {
        for (let character of text) {
            this.innerText += character;
            await new Promise(resolve => setTimeout(resolve, this.typeInterval));
        }
    }

    async delete(text) {
        for (let character of text) {
            this.innerText = this.innerText.slice(0, this.innerText.length - 1);
            await new Promise(resolve => setTimeout(resolve, this.typeInterval));
        }
    }
}

// Définition du custom element
customElements.define('type-async', TypeAsync, { extends: 'span' });