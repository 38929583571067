import $ from 'jquery';

export default class HeaderAnimateLogoWidth {

    constructor() {
        this.animTitleOpen = 400;
    }

    init() {
        var titleWidth = $('h1 .fat-title').width() + $('h1 .coders-title').width();
        $('.container-title').css('margin-top','-'+ ($('#type-text').height() / 2 ) +'px');
        $('h1').animate({
            width: titleWidth
        }, {
            duration: this.animTitleOpen,
            easing: 'swing', // Easing swing pour un effet fluide
            complete: function() {
                //__ set width to fit content
                $('.container-title').removeClass('brackets');
                $('.container-title h1').addClass('brackets');
                $('.container-title h1').css('width','fit-content');
                $('.container-title h1').css('position','absolute');
                $('.container-title h1').css('overflow','visible');
                $('body').css('overflow','visible');
            }
        });
    }
}