export default class HeadFaviconAnimation {
    constructor(favicons, interval = 300) {
        this.favicons = favicons;
        this.interval = interval;
        this.currentIcon = 0;
        this.faviconElement = null;
    }

    init() {
        this.animate();
    }

    //__ change favicon picture
    animate() {
        this.faviconElement = document.querySelector("link[rel='icon']") || document.createElement("link");
        this.faviconElement.href = this.favicons[this.currentIcon];
        this.currentIcon = (this.currentIcon + 1) % this.favicons.length;
        setTimeout(() => this.animate(), this.interval); // Utilisation de l'arrow function pour conserver le contexte `this`
    }
}