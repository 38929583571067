import "../scss/style.scss";//__ css inside <head><style>
import $ from 'jquery';

//__ head favicons animate
import HeadFaviconAnimation from './modules/HeadFaviconAnimation';
const favicons = [
    'images/favicons/favicon1.ico',
    'images/favicons/favicon2.ico',
    'images/favicons/favicon3.ico',
    'images/favicons/favicon3.ico',
    'images/favicons/favicon3.ico',
    'images/favicons/favicon3.ico',
    'images/favicons/favicon3.ico',
];
const headFaviconAnimation = new HeadFaviconAnimation(favicons);

//__ animate the header on scroll
import HeaderAnimation from './modules/HeaderAnimation';
const headerAnimation = new HeaderAnimation();

//__ animate the logo on load
import HeaderAnimateLogoWidth from './modules/HeaderAnimateLogoWidth';
const headerAnimateLogoWidth = new HeaderAnimateLogoWidth();

//__ subtitle code typing
import HeaderSubtitleCodeTypping from './modules/HeaderSubtitleCodeTypping';

// Main class
class Main {
    constructor() {

        $(window).ready(function () {
            //__ head favicon @TODO icons are continusly loaded, need to change attribute value only...
            headFaviconAnimation.init();
            //__ subtitle code typing
            new HeaderSubtitleCodeTypping("#type-text");

        })

        $(window).on('load', function () {
            //__ head title animate width
            headerAnimateLogoWidth.init();
            //__ animate the header on scroll
            headerAnimation.init();

            $('.mail-1').html('&#102;&#97;&#99;&#116;&#111;&#100;&#101;&#114;&#115;');
            $('.mail-2').html('&#64;');
            $('.mail-3').html('&#104;&#111;&#116;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;');

        });

    }

    init() {

    }
}


const main = new Main();
main.init();
